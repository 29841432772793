<template>
  <td
    v-if="isLoading"
    :colspan="colspanLength"
  >
    <v-row justify="center">
      <v-col cols="auto">
        <v-progress-circular
          class="mx-auto"
          color="primary"
          size="30"
          indeterminate
        ></v-progress-circular>
      </v-col>
    </v-row>
  </td>
  <td
    v-else-if="items.length"
    :colspan="colspanLength"
    class="white"
  >
    <v-container fluid>
      <v-sheet outlined class="pa-2 rounded">
        <v-data-table
          :items-per-page="100"
          :headers="displayedHeader"
          :items="items"
          item-key="id"
          hide-default-footer
          :server-items-length="1000"
        >
          <template v-slot:[`item.createdAt`]={item}>
            <span>{{ dayjs(item.createdAt).format('HH:mm') }}</span>
          </template>
        </v-data-table>
      </v-sheet>
    </v-container>
  </td>
</template>

<script>
import dayjs from 'dayjs';

export default {
  props: {
    isLoading: {
      type: Boolean,
      default: () => false,
    },
    parentMenuId: {
      type: Number,
      default: 0,
    },
    items: {
      type: Array,
      default: () => [],
    },
    colspanLength: {
      type: Number,
      default: () => 6,
    },
  },
  data() {
    return {
      headers: [
        {
          text: 'Shipper',
          value: 'companyName',
          width: '20%',
          sortable: false,
        },
        {
          text: 'Nomor Pesanan',
          value: 'shipmentTitle',
          width: '20%',
          sortable: false,
        },
        {
          text: 'Nomor Surat Jalan',
          value: 'travelDocumentNo',
          width: '20%',
          sortable: false,
        },
        {
          text: 'Pesanan Dibuat (Jam)',
          value: 'createdAt',
          width: '20%',
          sortable: false,
        },
        {
          text: 'Lokasi Asal',
          value: 'originLocationName',
          width: '20%',
          sortable: false,
        },
        {
          text: 'Lokasi Tujuan Akhir',
          value: 'destinationLocationName',
          width: '20%',
          sortable: false,
        },
      ],
    };
  },
  computed: {
    displayedHeader() {
      const { canUpdate } = this.userAccess;
      return this.headers.map((i) => ({
        ...i,
        class: 'black--text amber accent-3',
      })).filter((header) => {
        if (!canUpdate) {
          return header.value !== 'actions';
        }
        return header;
      });
    },
  },
  methods: {
    dayjs,
  },
};
</script>
